<template>
  <div id="hotel-list" ref="hotelsInfo" class="hotels-info wrapper" :class="{ 'hide-filters': removeFilters }">
    <HighlightedOffer class="hotels-info__offer-highlighted"></HighlightedOffer>
    <header class="hotels-info__header">
      <BlockTitle class="mb-2" v-bind="blockTitle" />
      <div class="hotels-info__options">
        <ListSort :filters="filtersBlock" :remove-filters="removeFilters" />
        <ListTabs />
      </div>
    </header>
    <ListFilters
      v-if="filters != null && !removeFilters && currentDevice.isDesktop"
      class="hotels-info__filters"
      :filters="filtersBlock"
    />
    <div class="hotels-info__hotels">
      <Transition name="fade" mode="out-in">
        <HotelList v-if="activeTab === 'list'" :offer-info="props.offerInfo" />
        <HotelsMap v-else-if="activeTab === 'map'" />
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { computed, onMounted, provide, ref, watch } from 'vue'

import {
  FILTER_OFFER_EVENT,
  FILTERS_IN_PARAMS,
} from '../../../../blocks/hotels_info_with_filtering_packed/hotels_info_with_filtering.constants'
import { currentDevice } from '../../../../core/utils/currentDevice'
import { closeLoader, openLoader } from '../../../../elements/wait_loading/wait_loading'
import getRealMarketContent, { dispatchRealMarketContentUpdatedEvent } from '../../../../utils/get_real_market_content'
import { loadStyles } from '../../mixins/LoadStyles/LoadStyles'

import { useHotelsInfoStore } from './store/hotels_info'

import BlockTitle from '../../components/BlockTitle/BlockTitle'
import HighlightedOffer from '../../components/HotelsInfo/HighlightedOffer/HighlightedOffer'
import HotelList from '../../components/HotelsInfo/HotelList/HotelList'
import HotelsMap from '../../components/HotelsInfo/HotelsMap/HotelsMap'
import ListFilters from '../../components/HotelsInfo/ListFilters/ListFilters'
import ListSort from '../../components/HotelsInfo/ListSort/ListSort'
import ListTabs from '../../components/HotelsInfo/ListTabs/ListTabs'

/**
 * Styles
 */
const COMPONENT_NAME = 'HotelsInfo'
loadStyles({ name: COMPONENT_NAME, folder: 'blocks' })

/**
 * Props
 */
const props = defineProps({
  title: {
    type: Object,
    default: () => ({
      text: '',
      html: 'h2',
      align: 'text-left',
    }),
  },
  hotels: {
    type: Array,
    default: () => [],
  },
  filters: {
    type: Object,
    default: () => ({}),
  },
  resourceTitle: {
    type: String,
    default: '',
  },
  i18n: {
    type: Object,
    default: () => ({}),
  },
  removeFilters: {
    type: Boolean,
    default: false,
  },
  constrainedViewId: {
    type: Number,
    default: 0,
  },
  blockId: {
    type: Number,
    default: 0,
  },
  jobId: {
    type: String,
    default: '',
  },
  offerInfo: {
    type: Object,
    default: () => ({}),
  },
})

/**
 * Refs
 */
const hotelsInfo = ref(null)
const filtersBlock = ref(props.filters)
const hotelsBlock = ref([])
const i18nBlock = ref(props.i18n)
const titleBlock = ref(props.title)

/**
 * Provide
 */
provide('i18n', i18nBlock)
provide('constrainedViewId', props.constrainedViewId)
provide('resourceTitle', props.resourceTitle)

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()

watch(hotelsBlock, newHotels => {
  if (newHotels.length && filtersBlock.value) {
    filtersBlock.value.offers?.options?.forEach(offer => {
      const hotelsIds = offer.value.hotel_ids
      hotelsIds.forEach(hotelId => {
        const hotel = newHotels.find(hotel => hotel.id === hotelId)
        if (hotel) {
          if (!hotel.offers) hotel.offers = []
          hotel.offers.push(offer.value.id)
        }
      })
    })
  }
  hotelsInfoStore.setHotels(newHotels)
})

hotelsBlock.value = props.hotels

const { activeTab } = storeToRefs(hotelsInfoStore)

/**
 * Computed
 */
const blockTitle = computed(() => {
  return {
    text: titleBlock.value.text.replace('{{counter}}', hotelsBlock.value.length),
    ...(titleBlock.value.html ? { html: titleBlock.value.html } : {}),
    ...(titleBlock.value.align ? { align: titleBlock.value.align } : {}),
  }
})

/**
 * Methods
 */
function scrollToTop() {
  // Include fastbooking height
  window.scrollTo({ top: hotelsInfo.value.offsetTop - 125, behavior: 'smooth' })
}

/**
 * Subscribe to the store to watch for current_page changes in order
 * to scroll to the top of the component smoothly
 */
hotelsInfoStore.$subscribe(({ events }) => {
  if (!hotelsInfo.value) return

  if (events.key === 'current_page') {
    scrollToTop()
  }
})

/**
 * Lifecycle
 */
onMounted(() => {
  // Listen for interactions in other modules applying filters to the offers
  document.addEventListener(FILTER_OFFER_EVENT, event => {
    const offerId = Number(event.detail.offerId)
    const offer = filtersBlock.value.offers.options.find(option => option.id === offerId)
    if (offer) {
      hotelsInfoStore.replaceFilters('offers', [offer.value])

      // Add params to URL so getRealMarketContent can use them later
      const params = new URLSearchParams(window.location.search)
      FILTERS_IN_PARAMS.forEach(filter => params.delete(filter))
      params.set('filter_offers', offerId)
      window.history.replaceState(null, null, `?${params}`)

      scrollToTop()
    }
  })
})

function updateHotelsWithCrsData(crs_data) {
  Object.keys(crs_data)
    .map(Number)
    .forEach(hotelId => {
      hotelsBlock.value.forEach(hotel => {
        if (hotel.id === hotelId) {
          const data = crs_data[hotelId]
          hotel.price_real = data.price
          hotel.show_all_inclusive = data.show_all_inclusive
          hotel.hide_price_condition = data.hide_price_condition
          hotel.price_custom_message = data.price_custom_message
          hotel.has_discounts = data.has_discounts
          hotel.updated_by_crs = true
        }
      })
    })
}

function getCrsAvailabilities(lang, job_id) {
  fetch(`${lang}/crs_availabilities_jobs/${job_id}/?price_custom_message=true&for_vue=true`)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`)
      }
      return response.json()
    })
    .then(data => {
      const { hotels, at, total } = data
      updateHotelsWithCrsData(hotels)
      IB.currencyForm.forceUpdate()
      if (at < total) {
        // Poll every 1000ms until all hotels are updated
        setTimeout(() => getCrsAvailabilities(lang, job_id), 1000)
      } else {
        closeLoader()
      }
    })
    .catch(error => {
      console.error(error)
      closeLoader()
      throw error
    })
}

if (props.jobId) {
  let lang = document.documentElement.getAttribute('data-js-lang')
  lang = lang !== 'es' ? `/${lang}` : ''
  openLoader()
  getCrsAvailabilities(lang, props.jobId)
} else {
  getRealMarketContent({ blockId: props.blockId, constrainedViewId: props.constrainedViewId }).then(response => {
    if (response) {
      filtersBlock.value = response?.filters
      hotelsBlock.value = response.hotels
      i18nBlock.value = response.i18n
      titleBlock.value = response.title
      hotelsInfoStore.resetFilters()
      dispatchRealMarketContentUpdatedEvent({ blockSelector: '.hotels-info', blockName: 'hotels-info' })
    }
  })
}
</script>
